<template>
  <div class="new-invoice mb-3">
    <b-row class="mb-2">
      <b-col>
        <b-btn
          variant="primary"
          class="border-0 border-bottom rounded-0"
          @click="showNewInvoice = !showNewInvoice"
        >New Manual Invoice</b-btn>
      </b-col>
    </b-row>
    <b-card v-show="showNewInvoice">
      <b-row>
        <b-col>
          <label for="new-invoice_project">Project: <span style="color: red">*</span></label>
          <b-form-select
            id="new-invoice_project"
            class="rounded-0 bg-light w-100 p-2"
            v-model="newInvoice.project"
          >
            <option v-for="project in projects" :value="project" :key="project._id + '_' + project.client_id">
              {{ project.name }}
            </option>
          </b-form-select>
        </b-col>
        <b-col>
          <label for="new-invoice_amount">Amount: <span style="color: red">*</span></label>
          <b-input
            id="new-invoice_amount"
            class="border rounded-0 bg-light w-100 p-2"
            type="number"
            v-model="newInvoice.amount"
          >
          </b-input>
        </b-col>
        <b-col>
          <label for="new-invoice_upsell">Upsell:</label>
          <switches id="new-invoice_upsell" class="rounded-0 bg-light w-100 p-2" v-model="newInvoice.upsell" :emit-on-mount="false" theme="bulma" color="blue"></switches>
        </b-col>
        <b-col>
          <label for="new-invoice_notes">Notes:</label>
          <b-input
            id="new-invoice_notes"
            class="border rounded-0 bg-light w-100 p-2"
            v-model="newInvoice.notes"
          >
          </b-input>
        </b-col>
        <b-col>
          <label for="new-invoice_creation_date">Payment Date: <span style="color: red">*</span></label>
          <date-picker id="new-invoice_creation_date" class="border bg-light mb-2 custom-datepicker" v-model="newInvoice.paid_date" valueType="format"></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-btn
            variant="primary"
            class="border-0 border-bottom rounded-0"
            @click="addNewInvoice"
          >Add</b-btn>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Switches from 'vue-switches'
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { showSnackbar } from '@/services'

export default {
  components: {
    DatePicker,
    Switches
  },
  name: 'ManualInvoiceComponent',
  data() {
    return {
      showNewInvoice: false,
      newInvoice: {},
      projects: []
    }
  },
  async mounted() {
    const clients = await MtApi.getClients()
    for (const client of clients) {
      if (!client.projects) {
        continue
      }
      for (const project of client.projects) {
        this.projects.push({
          client_id: client._id,
          _id: project._id,
          name: project.abbr_l + '-' + project.type
        })
      }
    }
    this.projects.sort((a, b) => {
      const textA = a.name.toUpperCase()
      const textB = b.name.toUpperCase()
      if (textA < textB) {
        return -1
      }
      if (textA > textB) {
        return 1
      }
      return 0
    })
  },
  methods: {
    async addNewInvoice() {
      if (!this.newInvoice.project) {
        showSnackbar({
          text: 'Project Not Selected',
          mode: 'error'
        })
        return false
      }
      if (!this.newInvoice.amount) {
        showSnackbar({
          text: 'Amount Not Set',
          mode: 'error'
        })
        return false
      }
      if (!this.newInvoice.paid_date) {
        showSnackbar({
          text: 'Paid Date Not Set',
          mode: 'error'
        })
        return false
      }
      const data = {
        event: 'create_invoice',
        field: {
          quickbook_id: 66000,
          quickbook_tech_id: 66001,
          amount: this.newInvoice.amount,
          upsell: this.newInvoice.upsell ?? false,
          status: 'paid',
          custom_note: this.newInvoice.notes,
          created_date: new Date(),
          project: this.newInvoice.project._id,
          payment_date: this.newInvoice.paid_date
        }
      }
      await MtApi.updateClient(this.newInvoice.project.client_id, data)
      this.newInvoice = {
        project: null,
        amount: 0,
        upsell: false,
        notes: '',
        paid_date: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-datepicker {
  height: 37px;
}
</style>
