<template>
  <div class="show-error-invoices">
    <label for="show-error-invoices">Empty / Error-filled</label>
    <input
      id="show-error-invoices"
      type="checkbox"
      v-model="showErrorInvoices"
      @change="change"
    >
  </div>
</template>

<script>
export default {
  name: 'header-show-error-invoices',
  data() {
    return {}
  },
  computed: {
    showErrorInvoices: {
      get() {
        return this.$store.getters.getShowErrorInvoices(this.$route.name)
      },
      set(value) {
        this.$store.dispatch('setShowErrorInvoices', { page: this.$route.name, query: value })
      }
    }
  },
  mounted() {
    this.$emitter.on('header_reset_filters', () => {
      this.showErrorInvoices = ''
    })
  },
  beforeDestroy() {
    this.$emitter.off('header_reset_filters')
  },
  methods: {
    change() {
      this.$emitter.emit('header_addons_changed')
    }
  }
}
</script>
