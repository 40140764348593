<template>
  <div class="line-popup">
    <pre>{{ json }}</pre>
  </div>
</template>
<script>

export default {
  data() {
    return {
      json: null
    }
  },
  mounted() {
    this.json = JSON.parse(this.$attrs.jsonString)
  }
}
</script>
<style lang="scss" scoped>
.line-popup {
  margin: 25px;
}
</style>
