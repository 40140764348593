<template>
  <div>
    <div class="sub-invoices-buttons" :style="{ paddingLeft: paddingLeft }">
      <div
        v-if="!parent"
        class="arrow"
        :style="{
          transform: rotation,
          cursor: 'pointer',
          display: 'inline-block'
        }"
        @click="onExpand"
      >
        <div class="arrow__ico">
          <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM9.35355 4.35355C9.54882 4.15829 9.54882 3.84171 9.35355 3.64645L6.17157 0.464466C5.97631 0.269204 5.65973 0.269204 5.46447 0.464466C5.2692 0.659728 5.2692 0.976311 5.46447 1.17157L8.29289 4L5.46447 6.82843C5.2692 7.02369 5.2692 7.34027 5.46447 7.53553C5.65973 7.7308 5.97631 7.7308 6.17157 7.53553L9.35355 4.35355ZM1 4.5H9V3.5H1V4.5Z" fill="#5F5F5F"/>
          </svg>
        </div>
      </div>
      {{ params.value }}<div style="margin-left: 5px" v-if="!parent && params.data.sub_invoices && params.data.sub_invoices.length > 0">({{ params.data.sub_invoices.length }})</div>
      <button v-if="!parent" class="add-row" @click="createSubRow"></button>
      <button v-if="!parent && repeat" class="repeat-sub-invoices" @click="repeatSubInvoices"></button>
      <button v-if="parent" class="delete-row" @click="deleteSubRow"></button>
      <button v-if="params.data.quickbook_id === 66000" class="delete-row" @click="deleteInvoice"></button>
      <b-icon v-show="params.data.quickbook_id === 66000" class="ml-2 cash" style="font-size: 15px" variant="primary" icon="cash-coin"></b-icon>
    </div>
  </div>
</template>

<script>
import CopySubInvoicePopup from '@/agGridV2/components/copy-subinvoice-popup.component.vue'
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopySubInvoicePopup
  },
  data() {
    return {
      paddingLeft: null,
      rotation: null,
      value: null,
      child: false,
      repeat: false,
      api: null,
      gocardless: null,
      parent: null
    }
  },
  mounted() {
    this.parent = this.params?.context?.masterGrid
    this.repeat = this.params.data?.repeat_invoices && this.params.data.repeat_invoices.length > 0
  },
  methods: {
    async createSubRow() {
      const currentSubInvoices = this.params.node.data.sub_invoices || []
      const emptyElement = {}
      const updatedSubInvoices = [...currentSubInvoices, emptyElement]
      this.params.node.setDataValue('project', null)
      this.params.node.setDataValue('sub_invoices', updatedSubInvoices)
      this.params.node.setExpanded(true)
      this.params.api.redrawRows()
    },
    async deleteSubRow() {
      const currentSubInvoices = this.parent.node.data.sub_invoices
      currentSubInvoices.splice(this.params.node.rowIndex, 1)
      const updatedSubInvoices = [...currentSubInvoices]
      this.parent.node.setDataValue('sub_invoices', updatedSubInvoices)
      this.params.api.redrawRows()
      this.parent.api.redrawRows()
    },
    async repeatSubInvoices() {
      this.$modal.show(
        CopySubInvoicePopup,
        {
        params: this.params
      }, {
        width: '700px'
      })
    },
    onExpand() {
      this.params.node.setExpanded(!this.params.node.expanded)
    },
    deleteInvoice() {
      const data = {
        event: 'delete_invoice',
        field: {
          invoice_id: this.params.data._id
        }
      }
      MtApi.updateClient(this.params.data.client_id, data)
      this.params.api.applyServerSideTransaction({ remove: [this.params.node.data] })
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-invoices-buttons {
  display: flex;
  align-items: center;
  .arrow {
    margin-right: 5px;
  }
}
.cash {
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 20%;
  right: 5px;
}
</style>
