<template>
  <div class="projects-select-renderer">
    <v-select
      v-if="!mixed"
      :class="dynamicClass"
      v-model="selected"
      :options="options"
      :no-async="true"
      appendToBody
      :getOptionLabel="
        (option) => {
          return option.abbr_l + '-' + option.type
        }
      "
      v-on:input="onSelectChange"
      :disabled="disabled"
    >
      <template #selected-option="{ abbr_l, type, status }">
        <span v-if="abbr_l && type">{{ abbr_l }}-{{ type }}</span>
        <span v-else-if="abbr_l && type && status === 'archived'" style="text-decoration: line-through">{{ abbr_l }}-{{ type }}</span>
        <span v-else>Select Project</span>
      </template>
      <template #option="{ abbr_l, type, status }">
        <span v-if="status === 'archived'" style="text-decoration: line-through">{{ abbr_l }}-{{ type }}</span>
        <span v-else>{{ abbr_l }}-{{ type }}</span>
      </template>
    </v-select>
    <div class="mixed" v-else-if="mixed === 'mixed'">Mixed</div>
    <div class="mixed-with-issue" v-else-if="mixed === 'mixed-with-issue'">Mixed With Issue</div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { parse } from "date-fns";

export default {
  data() {
    return {
      dynamicClass: '',
      options: [],
      selected: null,
      disabled: false,
      storageService: {},
      mixed: false
    }
  },
  watch: {
    selected(newSelected, oldSelected) {
      if (!newSelected?._id) {
        this.dynamicClass = 'v-select-empty'
      }
      if (this.params.data.sub_invoices && this.params.data.sub_invoices.length > 0) {
        this.mixed = 'mixed'
        const sub_invoices_amount = this.params.data.sub_invoices.reduce((acc, obj) => acc + parseInt(obj.amount, 10), 0)
        if (sub_invoices_amount !== this.params.data.amount) {
          this.mixed = 'mixed-with-issue'
        }
        for (const sub_invoice of this.params.data.sub_invoices) {
          if (!sub_invoice?.project?.name) {
            this.mixed = 'mixed-with-issue'
            break
          }
        }
      }
    }
  },
  mounted() {
    this.selected = this.params.value ?? {}
    this.options = this.params.data.projects ?? []
  },
  components: {
    vSelect
  },
  methods: {
    onSelectChange(selectedValue) {
      this.params.node.setDataValue('project', selectedValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.ag-has-focus > .ag-center-cols-clipper {
  z-index: 10 !important;
}
.ag-center-cols-container .ag-row:nth-last-child(-n+6):not(:nth-child(-n+6)) .vs__dropdown-menu {
  top: auto;
  bottom: 0;
}

.projects-select-renderer {
  &::v-deep {
    .v-select {
      height: calc(100% + 2px);
      position: absolute !important;
      top: -1px;
      left: -1px;
      width: calc(100% + 1px);
      .vs__dropdown-toggle {
        border-radius: 0;
        height: 100%;
        background-color: transparent;
        border: 0;
      }

      .vs__actions {
        svg {
          transform: scale(.8);
        }
      }

      &.vs--open {
        .vs__actions {
          svg {
            transform: scale(.8) rotate(180deg);
          }
        }
      }
    }

    .vs--single.vs--open .vs__selected, .vs--single.vs--loading .vs__selected {
      position: relative;
    }

    .v-select-empty {
      .vs__dropdown-toggle {
        background-color: #F7665E;

        .vs__selected,
        .vs__search {
          color: #fff;
        }
        .vs__open-indicator {
          fill: #fff;
        }
      }
    }
  }
}
</style>
