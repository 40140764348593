var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"projects-select-renderer"},[(!_vm.mixed)?_c('v-select',{class:_vm.dynamicClass,attrs:{"options":_vm.options,"no-async":true,"appendToBody":"","getOptionLabel":function (option) {
        return option.abbr_l + '-' + option.type
      },"disabled":_vm.disabled},on:{"input":_vm.onSelectChange},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
      var abbr_l = ref.abbr_l;
      var type = ref.type;
      var status = ref.status;
return [(abbr_l && type)?_c('span',[_vm._v(_vm._s(abbr_l)+"-"+_vm._s(type))]):(abbr_l && type && status === 'archived')?_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(abbr_l)+"-"+_vm._s(type))]):_c('span',[_vm._v("Select Project")])]}},{key:"option",fn:function(ref){
      var abbr_l = ref.abbr_l;
      var type = ref.type;
      var status = ref.status;
return [(status === 'archived')?_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(abbr_l)+"-"+_vm._s(type))]):_c('span',[_vm._v(_vm._s(abbr_l)+"-"+_vm._s(type))])]}}],null,false,563660871),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):(_vm.mixed === 'mixed')?_c('div',{staticClass:"mixed"},[_vm._v("Mixed")]):(_vm.mixed === 'mixed-with-issue')?_c('div',{staticClass:"mixed-with-issue"},[_vm._v("Mixed With Issue")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }