<template>
  <div class="copy-sub-invoices-wrapper">
      <v-select
        v-model="selected_invoice"
        :options="repeat_invoices"
        placeholder="Select Invoice"
        :clearable="false"
        :no-async="true"
        appendToBody
        label="quickbook_id"
      >
        <template #option="{ quickbook_id }">
          <span>{{ quickbook_id }}</span>
        </template>
      </v-select>
    <div class="sub-invoices" v-if="selected_invoice && selected_invoice.sub_invoices.length > 0">
      <div class="sub-invoice" v-for="sub_invoice in selected_invoice.sub_invoices" :key="sub_invoice._id">
        <p>Project: {{ sub_invoice.project.abbr_l }}-{{ sub_invoice.project.type }}, Amount: {{ sub_invoice.amount }}</p>
      </div>
    </div>
    <button class="button-outline-blue" @click="copySubInvoices" v-if="selected_invoice">Copy</button>
  </div>
</template>
<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
import vSelect from 'vue-select'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect
  },
  data() {
    return {
      repeat_invoices: [],
      selected_invoice: null
    }
  },
  async mounted() {
    this.repeat_invoices = this.$attrs.params.data.repeat_invoices || []
  },
  methods: {
    async copySubInvoices() {
      // Update Project
      this.$attrs.params.node.setDataValue('project', null)
      // Update Sub Invoices
      this.$attrs.params.api.setGridOption('context', {
        event: 'repeat_sub_invoices'
      })
      this.$attrs.params.node.setDataValue('sub_invoices', this.selected_invoice.sub_invoices)
      this.$modal.hideAll()
    }
  }
}
</script>
<style lang="scss" scoped>
.copy-sub-invoices-wrapper {
  padding: 10px;
  .sub-invoices {
    margin-top: 10px;
  }
}
</style>
